import { Action } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export interface ActionWithPayload<T> extends Action {
  payload?: T;
}

export const actionTypes = {
  GetPosProduct: "[GetPosProduct] Action",
};

const initialAuthState: ICenterState = {
  Posproducts: undefined,
};

export interface ICenterState {
  Posproducts?: any;
}

export const reducer = persistReducer(
  { storage, key: "v100-demo1-auth", blacklist: ["Posproducts"] },
  (
    state: ICenterState = initialAuthState,
    action: ActionWithPayload<ICenterState>
  ) => {
    switch (action.type) {
      case actionTypes.GetPosProduct: {
        const Posproducts = action.payload?.Posproducts;
        return { ...state, Posproducts };
      }
      default:
        return state;
    }
  }
);

export const actions = {
  GetPosProduct: (Posproducts: string) => ({
    type: actionTypes.GetPosProduct,
    payload: { Posproducts },
  }),
};
