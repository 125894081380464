import { Action } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
// import InvoiceList from "../InvoiceList";

export interface ActionWithPayload<T> extends Action {
  payload?: T;
}

export const actionTypes = {
  GetQRMemberAttendences: "[GetQRMemberAttendences] Action",
};

const initialAuthState: ICenterState = {
  QRMember: undefined,
};

export interface ICenterState {
  QRMember?: any;
}

export const reducer = persistReducer(
  { storage, key: "v100-demo1-auth", blacklist: ["QRMember"] },
  (
    state: ICenterState = initialAuthState,
    action: ActionWithPayload<ICenterState>
  ) => {
    switch (action.type) {
      case actionTypes.GetQRMemberAttendences: {
        const QRMember = action.payload?.QRMember;
        return { ...state, QRMember };
      }
      default:
        return state;
    }
  }
);

export const actions = {
  GetQRMemberAttendences: (QRMember: string) => ({
    type: actionTypes.GetQRMemberAttendences,
    payload: { QRMember },
  }),
};
