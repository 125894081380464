import { useFormik } from "formik";
// import "./setting.css";
import * as Yup from "yup";
import { useIntl } from "react-intl";
import { Form } from "react-bootstrap";
import { UpdateCompany } from "../redux/settingCRUD";
import Select from "react-select/dist/declarations/src/Select";
const Smtp = ({
  smsdata,
  setSmtpData,
  UpdateUser,
  ActiveBranchID,
  ActiveBranchUUID,
  detailsCompany,
  setSuccessResponse,
  setErrorResponse,
  setErr,
  ActiveBranchSettingUUID,
}: any) => {
  const intl = useIntl();
  const Schemas = Yup.object().shape({
    SMTPUserName: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "REQ.MODULE.SMS.USERNAME" })),
    SMTPPassword: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "REQ.MODULE.SMS.PASSWORD" })),
    SMTPHost: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "REQ.MODULE.SMS.SMTPHost" })),
    SMTPFromEmail: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "REQ.MODULE.SMS.SMTPFromEmail" })),
    SMTPPort: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "REQ.MODULE.SMS.SMTPPort" })),
    SMTPSecure: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "REQ.MODULE.SMS.SMTPSecure" })),
    SMTPrequireTLS: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "REQ.MODULE.SMS.SMTPrequireTLS" })),
    SMTPStatus: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "REQ.MODULE.SMS.Status" })),
  });
  const initialState = {
    SMTPUserName: UpdateUser?.SMTPUserName,
    SMTPPassword: UpdateUser?.SMTPPassword,
    SMTPHost: UpdateUser?.SMTPHost,
    SMTPFromEmail: UpdateUser?.SMTPFromEmail,
    SMTPPort: UpdateUser?.SMTPPort,
    SMTPSecure: UpdateUser?.SMTPSecure || "true",
    SMTPrequireTLS: UpdateUser?.SMTPrequireTLS || "true",
    SMTPStatus: UpdateUser?.SMTPStatus || "Active",
    SMTPSupplementStatus: UpdateUser?.SMTPSupplementStatus,
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialState,

    validationSchema: Schemas,

    onSubmit: async (values) => {
      // console.log(values, "valee");
      setSmtpData(values);
      const formData = {
        smsCompany: UpdateUser?.smsCompany || "",
        smsUsername: UpdateUser?.smsUsername || "",
        smsPassword: UpdateUser?.smsPassword || "",
        smsSenderID: UpdateUser?.smsSenderID || "",
        smsStatus: UpdateUser?.smsStatus || "",
        smsSupplementStatus: UpdateUser?.smsSupplementStatus || "",
        iBranchSettingUUID:
          UpdateUser?.iBranchSettingUUID || ActiveBranchSettingUUID,
        branchID: ActiveBranchID || "",
        branchUUID: ActiveBranchUUID || "",
        courrencyID: UpdateUser?.iCountryCurrencyID || "",
        dataFormate: UpdateUser?.idateFormate || "",
        timeFormate: UpdateUser?.itimeFormate || "",
        qrAttendanceLogType: UpdateUser?.displayQRAttendanceLogType || "",
        minSuppProductNotify: UpdateUser?.minSuppProductNotify || "",
        membershipFreezeShargeable:
          UpdateUser?.membershipFreezeChargeable || "",
        invoiceNotes: UpdateUser?.InvoiceNotes || "",
        invoiceSupplementNotes: UpdateUser?.suppInvocieNotes || "",
        invoiceprefx: UpdateUser?.inVoicePrefix || "",
        expiredPackageDays: UpdateUser?.expiredListInDays || "",
        whatsAppCompany: UpdateUser?.whatsAppCompany || "",
        whatsAppUsername: UpdateUser?.whatsUsername || "",
        whatsaAppPassword: UpdateUser?.whatsaAppPassword || "",
        whatsAppBalToken: UpdateUser?.whatsAppBalToken || "",
        whatsAppStatus: UpdateUser?.whatsAppStatus || "",
        whatsAppSupplementStatus: UpdateUser?.whatsAppSupplementStatus || "",
        SMTPUserName: values?.SMTPUserName,
        SMTPPassword: values?.SMTPPassword,
        SMTPHost: values?.SMTPHost,
        SMTPFromEmail: values?.SMTPFromEmail,
        SMTPPort: values?.SMTPPort,
        SMTPSecure: values?.SMTPSecure,
        SMTPrequireTLS: values?.SMTPrequireTLS,
        SMTPStatus: values?.SMTPStatus,
        SMTPSupplementStatus: values?.SMTPSupplementStatus || "",
      };
      const response = await UpdateCompany(formData);
      if (response.status === 200) {
        detailsCompany();
        setSmtpData(response);
        setSuccessResponse(true);
        setErrorResponse(false);
        setErr("");
      } else {
        setSuccessResponse(false);
        setErr(response?.data?.replyMsg);
        setErrorResponse(true);
        if (Array.isArray(response?.data?.replyMsg)) {
          let er = response?.data?.replyMsg;
          setErr(er);
        }
      }
    },
  });
  return (
    <div className="container form">
      <Form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-md-4">
            <div className="mb-10">
              <label className="required form-label">
                {intl.formatMessage({
                  id: "MODULE.SETTING.SMTPUSERNAME",
                })}
              </label>
              <input
                type="text"
                name="SMTPUserName"
                className="form-control"
                placeholder={intl.formatMessage({ id: "PLACEHOLDER.USERNAME" })}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.SMTPUserName}
              />
              {formik.errors.SMTPUserName && (
                <div className="error mt-2">
                  {formik.touched.SMTPUserName && formik.errors.SMTPUserName}
                </div>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="mb-10">
              <label className="required form-label">
                {intl.formatMessage({
                  id: "MODULE.SETTING.SMTPPASSWORD",
                })}
              </label>
              <input
                type="text"
                name="SMTPPassword"
                className="form-control"
                placeholder={intl.formatMessage({ id: "PLACEHOLDER.PASSWORD" })}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.SMTPPassword}
              />
              {formik.errors.SMTPPassword && (
                <div className="error mt-2">
                  {formik.touched.SMTPPassword && formik.errors.SMTPPassword}
                </div>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="mb-10">
              <label className="required form-label">
                {intl.formatMessage({
                  id: "MODULE.SETTING.SMTPHOSTNAME",
                })}
              </label>
              <input
                type="text"
                name="SMTPHost"
                className="form-control"
                placeholder={intl.formatMessage({ id: "PLACEHOLDER.HOSTNAME" })}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.SMTPHost}
              />
              {formik.errors.SMTPHost && (
                <div className="error mt-2">
                  {formik.touched.SMTPHost && formik.errors.SMTPHost}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <div className="mb-10">
              <label className="required form-label">
                {intl.formatMessage({
                  id: "MODULE.SETTING.SMTPFROMEMAIL",
                })}
              </label>
              <input
                type="text"
                name="SMTPFromEmail"
                className="form-control"
                placeholder={intl.formatMessage({ id: "PLACEHOLDER.EMAIL" })}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.SMTPFromEmail}
              />
              {formik.errors.SMTPFromEmail && (
                <div className="error mt-2">
                  {formik.touched.SMTPFromEmail && formik.errors.SMTPFromEmail}
                </div>
              )}
            </div>
          </div>

          <div className="col-md-4">
            <div className="mb-3">
              <label htmlFor="SMTPPort" className="required form-label">
                {intl.formatMessage({ id: "MODULE.SETTING.SMTPPORT" })}
              </label>

              <input
                type="text"
                id="SMTPPort"
                name="SMTPPort"
                placeholder={intl.formatMessage({
                  id: "PLACEHOLDER.SMTP.PORT",
                })}
                className="form-control"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.SMTPPort}
              />
              {formik.errors.SMTPPort && (
                <div className="error mt-2">
                  {formik.touched.SMTPPort && formik.errors.SMTPPort}
                </div>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="mb-3">
              <label htmlFor="SMTPSecure" className="form-label">
                {intl.formatMessage({
                  id: "MODULE.SETTING.SMTPSECURE",
                })}
              </label>
              <select
                id="SMTPSecure"
                name="SMTPSecure"
                className="required form-select"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.SMTPSecure}
              >
                <option value="true">
                  {intl.formatMessage({ id: "COMMON.TRUE" })}
                </option>
                <option value="false">
                  {intl.formatMessage({ id: "COMMON.FALSE" })}
                </option>
              </select>
              {formik.errors.SMTPSecure && (
                <div className="error mt-2">
                  {formik.touched.SMTPSecure && formik.errors.SMTPSecure}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <div className="mb-10">
              <label className="required form-label">
                {intl.formatMessage({
                  id: "MODULE.SETTING.SMTPREQUIRETLS",
                })}
              </label>
              <select
                name="SMTPrequireTLS"
                className="form-select"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.SMTPrequireTLS}
              >
                <option value="true">
                  {intl.formatMessage({ id: "COMMON.TRUE" })}
                </option>
                <option value="false">
                  {intl.formatMessage({ id: "COMMON.FALSE" })}
                </option>
              </select>
              {formik.errors.SMTPrequireTLS && (
                <div className="error mt-2">
                  {formik.touched.SMTPrequireTLS &&
                    formik.errors.SMTPrequireTLS}
                </div>
              )}
            </div>
          </div>

          <div className="col-md-4">
            <div className="mb-3">
              <label htmlFor="smsStatus" className="required form-label">
                {intl.formatMessage({ id: "MODULE.SETTING.SMTPSTATUS" })}
              </label>
              <select
                id="smsStatus"
                name="SMTPStatus"
                className="form-select"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.SMTPStatus}
              >
                <option value="Active">
                  {intl.formatMessage({ id: "COMMON.ACTIVE" })}
                </option>
                <option value="Inactive">
                  {intl.formatMessage({ id: "COMMON.INACTIVE" })}
                </option>
              </select>
              {formik.errors.SMTPStatus && (
                <div className="error mt-2">
                  {formik.touched.SMTPStatus && formik.errors.SMTPStatus}
                </div>
              )}
            </div>
          </div>
          {/* <div className="col-md-4">
            <div className="mb-3">
              <label htmlFor="smsSupplementStatus" className="form-label">
                {intl.formatMessage({
                  id: "MODULE.SETTING.SMTPSUPPLYMENTSTATUS",
                })}
              </label>
              <select
                id="smsSupplementStatus"
                name="SMTPSupplementStatus"
                className="form-select"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.SMTPSupplementStatus}
              >
                <option value="Active">
                  {intl.formatMessage({ id: "COMMON.ACTIVE" })}
                </option>
                <option value="Inactive">
                  {intl.formatMessage({ id: "COMMON.INACTIVE" })}
                </option>
              </select>
            </div>
          </div> */}
        </div>
        <button type="submit" className="btn btn-primary btn-block m-4">
          {intl.formatMessage({
            id: "MODULE.PACKAGEPERIOD.SAVEBUTTON",
          })}
        </button>
      </Form>
    </div>
  );
};

export default Smtp;
