import { Action } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export interface ActionWithPayload<T> extends Action {
  payload?: T;
}

export const actionTypes = {
  GetPurchaseProduct: "[GetPurchaseProduct] Action",
};

const initialAuthState: ICenterState = {
  purchaseProduct: undefined,
};

export interface ICenterState {
  purchaseProduct?: any;
}

export const reducer = persistReducer(
  { storage, key: "v100-demo1-auth", blacklist: ["purchaseProduct"] },
  (
    state: ICenterState = initialAuthState,
    action: ActionWithPayload<ICenterState>
  ) => {
    switch (action.type) {
      case actionTypes.GetPurchaseProduct: {
        const purchaseProduct = action.payload?.purchaseProduct;
        return { ...state, purchaseProduct };
      }
      default:
        return state;
    }
  }
);

export const actions = {
  GetPurchaseProduct: (purchaseProduct: string) => ({
    type: actionTypes.GetPurchaseProduct,
    payload: { purchaseProduct },
  }),
};
