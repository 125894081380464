import { reducer } from "./../../_metronic/layout/components/header/redux/HeadersRedux";
import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "../../app/modules/auth";
import * as role from "../../app/modules/role";

import * as plan from "../../app/modules/plans";
import * as members from "../../app/modules/members";
import * as department from "../../app/modules/employees/modules/department";
import * as invoice from "../../app/modules/finance/modules/invoice/component/redux/invoiceRedux";
import * as packagegroup from "../../app/modules/master/modules/packageGroup";
import * as packageperiod from "../../app/modules/master/modules/packagePeriod";
import * as packageRedux from "../../app/modules/master/modules/package";
import * as BatchRedux from "../../app/modules/master/modules/Batch";
import * as designation from "../../app/modules/employees/modules/designation";
import * as branches from "../../app/modules/branches";
import * as headers from "../../_metronic/layout/components/header";
import * as Memberattendences from "../../app/modules/attendance/modules/Member/redux/MemberAttendenceRedux";
import * as remainingBalances from "../../app/modules/finance/modules/remaining_balance/redux/remaingBalanceREDUX";
import * as Employeattendences from "../../app/modules/attendance/modules/employee/component/redux/employeeAttandanceREDUX";
import * as Expenses from "../../app/modules/expenses/modules/expense/Redux/ExpenseRedux";
import * as pos from "../../app/modules/pos/modules/supplement/Redux/SupplementRedux";
import * as Supplyments from "../../app/modules/pos/modules/supplement/Redux/SupplementRedux";
import * as SMS from "../../app/modules/Template/sms/redux/SMSREDUX";
import * as Whatsapp from "../../app/modules/Template/whatsapp/redux/WhatsappREDUX";
import * as Email from "../../app/modules/Template/email/redux/EmailREDUX";
import * as Expires from "../../app/modules/memberships/modules/expire-package/component/redux/renewalRedux";
import * as FollowUps from "../../app/modules/leads/modules/followUp/redux/followUpREDUX";
import * as addONS from "../../app/modules/memberships/modules/add-on/component/redux/AddOnRedux";
import * as products from "../../app/modules/pos/modules/supplementProduct/redux/SupplementProductRedux";
import * as Usermembers from "../../app/memberModules/MemberShip/redux/MembersRedux";
import * as UserMemberattendences from "../../app/memberModules/myAttandance/redux/MemberAttendenceRedux";
import * as Userinvoices from "../../app/memberModules/finance/invoice/redux/invoiceRedux";
import * as UserremainingBalances from "../../app/memberModules/finance/remainingbalance/redux/remaingBalanceREDUX";
import * as UserSupplyments from "../../app/memberModules/POS/Redux/SupplementRedux";
import * as UserFreezePackages from "../../app/memberModules/mypackageFreeze/redux/FreezeRedux";
import * as biometricMember from "../../app/modules/attendance/modules/biometric_log/component/redux/MemberRedux";
import * as biometricEmployee from "../../app/modules/attendance/modules/biometric_log/component/redux/EmployeeRedux";
import * as UserMemberlogattendences from "../../app/memberModules/biometriclog/redux/biometricREDUX";
import * as tickets from "../../app/modules/support/redux/supportREDUX";
import loaderReducer from "../../app/modules/Genric componant/spinner/redux/spinnerREDUX";
import * as reminders from "../../app/modules/quickLinks/modules/reminder/redux/reminderREDUX";
import * as activities from "../../app/modules/quickLinks/modules/activity/redux/ActivityREDUX";
import * as notifications from "../../app/modules/quickLinks/modules/notifications/redux/notificationREDUX";
import * as fitnesscenters from "../../app/chennalPartner/fitnesscenter/redux/FitnessRedux";
import * as moneyRequest from "../../app/chennalPartner/moneyRequest/redux/MoneyREDUX";
import * as image from "../../app/modules/company/redux/companyRedux";
import topbarReducer from "../../app/Topbar/redux/TopbarRedux";
import * as bodyMeasuremnet from "../../app/modules/body assistment/componant/bodyMeasurement/redux/BodyMeasurementRedux";
//import { imageReducer } from "../../app/modules/company/redux/companyRedux";
import * as foodItem from "../../app/modules/Deit plan/FoodItme/redux/FoodItemRedux";
import * as workouts from "../../app/modules/workout/redux/WorkoutRedux";
import * as deitPlan from "../../app/modules/Deit plan/plan/redux/PlanREDUX";
import * as MyDietPlan from "../../app/modules/body assistment/componant/My DietPlan/redux/MyDietPlanRedux";
import * as workoutplans from "../../app/modules/workouts/workoutPlans/Componants/redux/WorkoutRedux";
import * as Posproducts from "../../app/modules/new Pos/modules/Product/redux/ProductPosRedux";
import * as supplier from "../../app/modules/new Pos/modules/supplier/redux/SupplierRedux";
import * as purchaseProduct from "../../app/modules/new Pos/modules/purchase_Prodcut/redux/purchaseProductRedux";
import * as paymentPurchase from "../../app/modules/new Pos/modules/purchase_Prodcut/redux/PaymentRedux";
import * as paymentSell from "../../app/modules/new Pos/modules/saleProductPayment/redux/SaleProductPaymentREDUX";
import * as customer from "../../app/modules/new Pos/modules/Customer/redux/CustomerREDUX";
import * as logTypes from "../../app/modules/master/modules/logType/redux/LogTypeREDUX";
import * as Qrmemberlogs from "../../app/memberModules/qrCode/redux/QRcodeLogsREDUX";
import * as QREmployee from "../../app/modules/attendance/modules/qrcode_log/component/redux/EmployeeRedux";
import * as QRMember from "../../app/modules/attendance/modules/qrcode_log/component/redux/MemberRedux";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  role: role.reducer,
  plan: plan.reducer,

  //new modules
  branches: branches.reducer,
  department: department.reducer,
  packagegroup: packagegroup.reducer,
  packageperiod: packageperiod.reducer,
  package: packageRedux.reducer,
  batch: BatchRedux.reducer,
  designation: designation.reducer,
  members: members.reducer,
  headers: headers.reducer,
  invoices: invoice.reducer,
  remainingBalances: remainingBalances.reducer,
  Memberattendences: Memberattendences.reducer,
  Employeattendences: Employeattendences.reducer,
  Expenses: Expenses.reducer,
  pos: pos.reducer,
  Supplyments: Supplyments.reducer,
  SMS: SMS.reducer,
  Whatsapp: Whatsapp.reducer,
  Email: Email.reducer,
  Expires: Expires.reducer,
  FollowUps: FollowUps.reducer,
  addONS: addONS.reducer,
  products: products.reducer,
  Usermembers: Usermembers.reducer,
  UserMemberattendences: UserMemberattendences.reducer,
  Userinvoices: Userinvoices.reducer,
  UserremainingBalances: UserremainingBalances.reducer,
  UserSupplyments: UserSupplyments.reducer,
  UserFreezePackages: UserFreezePackages.reducer,
  biometricMember: biometricMember.reducer,
  biometricEmployee: biometricEmployee.reducer,
  UserMemberlogattendences: UserMemberlogattendences.reducer,
  tickets: tickets.reducer,
  loader: loaderReducer,
  CompanyImage: image.reducer,
  reminders: reminders.reducer,
  activities: activities.reducer,
  notifications: notifications.reducer,
  fitnesscenter: fitnesscenters.reducer,
  moneyRequest: moneyRequest.reducer,
  topbarData: topbarReducer,
  bodyMeasuremnet: bodyMeasuremnet.reducer,
  foodItem: foodItem.reducer,
  workouts: workouts.reducer,
  Posproducts: Posproducts.reducer,
  supplier: supplier.reducer,
  deitPlan: deitPlan.reducer,
  MyDietPlan: MyDietPlan.reducer,
  workoutplans: workoutplans.reducer,
  purchaseProduct: purchaseProduct.reducer,
  paymentPurchase: paymentPurchase.reducer,
  customer: customer.reducer,
  paymentSell: paymentSell.reducer,
  logTypes: logTypes.reducer,
  Qrmemberlogs: Qrmemberlogs.reducer,
  QREmployee: QREmployee.reducer,
  QRMember: QRMember.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export function* rootSaga() {
  yield all([auth.saga()]);
}
