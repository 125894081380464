import { useFormik } from "formik";
// import "./setting.css";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useIntl } from "react-intl";
import { Form } from "react-bootstrap";
import Select from "react-select";
import data from "../../../../config.json";
import { TimeZoneChange, UpdateCompany } from "../redux/settingCRUD";
import swal from "sweetalert";
const GenralSetting = ({
  setGenralSetting,
  UpdateUser,
  currency,
  ActiveBranchUUID,
  ActiveBranchID,
  detailsCompany,
  setSuccessResponse,
  setErrorResponse,
  ActiveBranchSettingUUID,
  setErr,
}: any) => {
  const intl = useIntl();
  const Schemas = Yup.object().shape({
    iCountryCurrencyID: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "REQ.SETTING.GENRAL.CURRENCY" })),
    idateFormate: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "REQ.SETTING.GENRAL.DFORMATE" })),
    InvoiceNotes: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "REQ.SETTING.GENRAL.INVOICENOTES" })),
    suppInvocieNotes: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "REQ.SETTING.GENRAL.POSNOTES" })),
    timeZoneID: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "VALIDATION.TIMEZONE" })),
    qrAttendanceLogType: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "VALIDATION.QRLOGTYPE" })),
    minSuppProductNotify: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "VALIDATION.MINPRODUCTNOTIFY" })),
  });
  const initialState = {
    iCountryCurrencyID: UpdateUser?.iCountryCurrencyID,
    idateFormate: UpdateUser?.idateFormate,
    qrAttendanceLogType: UpdateUser?.displayQRAttendanceLogType,
    minSuppProductNotify: UpdateUser?.minSuppProductNotify,
    itimeFormate: UpdateUser?.itimeFormate,
    InvoiceNotes: UpdateUser?.InvoiceNotes,
    suppInvocieNotes: UpdateUser?.suppInvocieNotes,
    inVoicePrefix: UpdateUser?.inVoicePrefix,
    expiredListInDays: UpdateUser?.expiredListInDays,
    timeZoneID: UpdateUser?.iTimezoneID,
  };
  const [selectCurrency, setSelectCurrency] = useState<any>("");
  const [timeZoneOption, setTimeZoneOption] = useState([]);
  const [selectTimeZone, setSelectTimeZone] = useState<any>("");
  const [selectlogtype, setSelectLogType] = useState<any>("");

  const logTypesOpt = [
    { value: 1, label: intl.formatMessage({ id: "COMMON.YES" }) },
    { value: 0, label: intl.formatMessage({ id: "COMMON.NO" }) },
  ];

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialState,

    validationSchema: Schemas,

    onSubmit: async (values) => {
      // console.log(values, "valee");
      setGenralSetting(values);
      const formData = {
        smsCompany: UpdateUser?.smsCompany || "",
        smsUsername: UpdateUser?.smsUsername || "",
        smsPassword: UpdateUser?.smsPassword || "",
        smsSenderID: UpdateUser?.smsSenderID || "",
        smsStatus: UpdateUser?.smsStatus || "",
        smsSupplementStatus: UpdateUser?.smsSupplementStatus || "",
        iBranchSettingUUID:
          UpdateUser?.iBranchSettingUUID || ActiveBranchSettingUUID,
        branchID: ActiveBranchID || "",
        branchUUID: ActiveBranchUUID || "",
        courrencyID: values?.iCountryCurrencyID,
        timeZoneID: values?.timeZoneID,
        dataFormate: values?.idateFormate,
        timeFormate: values?.itimeFormate || "",
        qrAttendanceLogType: values?.qrAttendanceLogType || "",
        minSuppProductNotify: values?.minSuppProductNotify || "",
        membershipFreezeShargeable:
          UpdateUser?.membershipFreezeChargeable || "",
        invoiceNotes: values?.InvoiceNotes,
        invoiceSupplementNotes: values?.suppInvocieNotes,
        invoiceprefx: values?.inVoicePrefix || "",
        expiredPackageDays: 0,
        whatsAppCompany: UpdateUser?.whatsAppCompany || "",
        whatsAppUsername: UpdateUser?.whatsUsername || "",
        whatsaAppPassword: UpdateUser?.whatsaAppPassword || "",
        whatsAppBalToken: UpdateUser?.whatsAppBalToken || "",
        whatsAppStatus: UpdateUser?.whatsAppStatus || "",
        whatsAppSupplementStatus: UpdateUser?.whatsAppSupplementStatus || "",
        SMTPUserName: UpdateUser?.SMTPUserName || "",
        SMTPPassword: UpdateUser?.SMTPPassword || "",
        SMTPHost: UpdateUser?.SMTPHost || "",
        SMTPFromEmail: UpdateUser?.SMTPFromEmail || "",
        SMTPPort: UpdateUser?.SMTPPort || "",
        SMTPSecure: UpdateUser?.SMTPSecure || "",
        SMTPrequireTLS: UpdateUser?.SMTPrequireTLS || "",
        SMTPStatus: UpdateUser?.SMTPStatus || "",
        SMTPSupplementStatus: UpdateUser?.SMTPSupplementStatus || "",
      };
      const response = await UpdateCompany(formData);
      // console.log(response, "resultUpated");
      if (response.status === 200) {
        detailsCompany();
        setGenralSetting(response);
        setSuccessResponse(true);
        setErrorResponse(false);
        setErr("");
      } else {
        setSuccessResponse(false);
        setErrorResponse(true);
        setErr(response?.data?.replyMsg);
        if (Array.isArray(response?.data?.replyMsg)) {
          let er = response?.data?.replyMsg;
          setErr(er);
        }
      }
    },
  });
  useEffect(() => {
    if (UpdateUser?.iCountryCurrencyID) {
      // const filter = currency?.filter(
      //   (item: any) =>
      //     item?.iCountryCurrencyID === UpdateUser?.iCountryCurrencyID
      // );
      // console.log(filter, "filter");
      setSelectCurrency({
        value: UpdateUser?.iCountryCurrencyID,
        label: `${UpdateUser?.currencyCode} ${UpdateUser?.currencyName}`,
      });
    }

    if (UpdateUser?.iTimezoneID) {
      setSelectTimeZone({
        value: UpdateUser?.iTimezoneID,
        label: `${UpdateUser?.description} ${UpdateUser?.utc_time_zone}`,
      });
    }
    if (UpdateUser?.displayQRAttendanceLogType) {
      setSelectLogType({
        value: UpdateUser?.displayQRAttendanceLogType,
        label:
          UpdateUser?.displayQRAttendanceLogType === "1"
            ? intl.formatMessage({ id: "COMMON.YES" })
            : intl.formatMessage({ id: "COMMON.NO" }),
      });
    }
  }, [
    UpdateUser?.iCountryCurrencyID,
    UpdateUser?.iTimezoneID,
    UpdateUser?.displayQRAttendanceLogType,
  ]);

  console.log(UpdateUser?.iTimezoneID);

  useEffect(() => {
    timeZoneChange();
  }, []);

  const timeZoneChange = async () => {
    const response = await TimeZoneChange();
    setTimeZoneOption(response.data.data);
    console.log("Timezone:", timeZoneOption);
  };

  const handleCurrencyChange = (Event: any) => {
    setSelectCurrency({ value: Event.value, label: Event.label });
    formik.setFieldValue("iCountryCurrencyID", Event.value);
  };

  const handleTimeZoneChange = (Event: any) => {
    setSelectTimeZone({ value: Event.value, label: Event.label });
    formik.setFieldValue("timeZoneID", Event.value);
    console.log("VALUE", Event.value);
  };
  const handleLogTypeChange = (Event: any) => {
    setSelectLogType({ value: Event.value, label: Event.label });
    formik.setFieldValue("qrAttendanceLogType", Event.value);
    console.log("VALUE", Event.value);
  };
  return (
    <div className="container form">
      <Form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-md-4 mb-4">
            <label htmlFor="iCountryCurrencyID" className="required form-label">
              {intl.formatMessage({ id: "MODULE.SETTING.GENRAL.CURRENCYCODE" })}
            </label>
            <Select
              className="basic-single"
              name="iCountryCurrencyID"
              id="iCountryCurrencyID"
              value={selectCurrency}
              placeholder={intl.formatMessage({
                id: "PLACEHOLDER.CURRENCY",
              })}
              onChange={(e) => handleCurrencyChange(e)}
              options={currency?.map((item: any) => ({
                value: item.iCountryCurrencyID,
                label: `${item.currencyCode} ${item.currencyName}`,
              }))}
            />
            {formik.errors.iCountryCurrencyID && (
              <div className="error mt-2">
                {formik.touched.iCountryCurrencyID &&
                  formik.errors.iCountryCurrencyID}
              </div>
            )}
            {/* <div className="error mt-2">
                {formik.touched.countryCode && formik.errors.countryCode}
              </div> */}
          </div>
          <div className="col-md-4 mb-4">
            <label htmlFor="idateFormate" className="required form-label">
              {intl.formatMessage({ id: "MODULE.SETTING.GENRAL.DATE" })}
            </label>
            <Select
              className="basic-single"
              name="idateFormate"
              id="idateFormate"
              placeholder={intl.formatMessage({
                id: "PLACEHOLDER.TIMEFORMAT",
              })}
              value={
                formik.values.idateFormate
                  ? {
                      value: formik.values.idateFormate,
                      label: formik.values.idateFormate,
                    }
                  : null
              }
              onChange={(options) =>
                formik.setFieldValue("idateFormate", options?.value)
              }
              options={data?.date?.map((item: any) => ({
                value: item,
                label: item,
              }))}
            />
            {formik.errors.idateFormate && (
              <div className="error mt-2">
                {formik.touched.idateFormate && formik.errors.idateFormate}
              </div>
            )}
            {/* <div className="error mt-2">
                {formik.touched.countryCode && formik.errors.countryCode}
              </div> */}
          </div>
          {/* <div className="col-md-4">
            <label
              htmlFor="itimeFormate"
              className="required fw-bold mb-2.5 block text-black dark:text-white"
            >
              {intl.formatMessage({ id: "MODULE.SETTING.GENRAL.TIME" })}
            </label>
            <Select
              className="basic-single"
              name="itimeFormate"
              id="itimeFormate"
              value={{
                value: formik.values.itimeFormate,
                label: formik.values.itimeFormate,
              }}
              onChange={(options) =>
                formik.setFieldValue("itimeFormate", options?.value)
              }
              options={data?.time?.map((item: any) => ({
                value: item,
                label: item,
              }))}
            />
           
          </div> */}
          {/* </div> */}
          {/* <div className="row mt-4"> */}
          {/* <div className="col-md-4">
            <div className="mb-10">
              <label className="required form-label">
                {intl.formatMessage({
                  id: "MODULE.SETTING.GENRAL.EXPIREDPACKAGES",
                })}
              </label>
              <input
                type="number"
                name="expiredListInDays"
                className="form-control"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.expiredListInDays}
              />
            </div>
          </div> */}
          {/* </div> */}

          {/* <div className="row"> */}
          <div className="col-md-4">
            <div className="mb-3">
              <label
                htmlFor="timeZoneID"
                className="required fw-bold mb-2.5 block text-black dark:text-white"
              >
                {intl.formatMessage({ id: "MODULE.SETTING.GENRAL.TIME" })}
              </label>
              <Select
                className="basic-single"
                name="timeZoneID"
                id="timeZoneID"
                value={selectTimeZone}
                onChange={(e) => handleTimeZoneChange(e)}
                options={timeZoneOption?.map((item: any) => ({
                  value: item.iTimezoneID,
                  label: `${item.description} ${item.utc_time_zone}`,
                }))}
              />
              {formik.errors.timeZoneID && (
                <div className="error mt-2">
                  {formik.touched.timeZoneID && formik.errors.timeZoneID}
                </div>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="mb-3">
              <label
                htmlFor="qrAttendanceLogType"
                className="required fw-bold mb-2.5 block text-black dark:text-white"
              >
                {intl.formatMessage({ id: "MODULE.SETTING.GENRAL.QRLOGTYPE" })}
              </label>
              <Select
                className="basic-single"
                name="qrAttendanceLogType"
                id="qrAttendanceLogType"
                value={selectlogtype}
                onChange={(e) => handleLogTypeChange(e)}
                options={logTypesOpt?.map((item: any) => ({
                  value: item.value,
                  label: item.label,
                }))}
              />
              {formik.errors.qrAttendanceLogType && (
                <div className="error mt-2">
                  {formik.touched.qrAttendanceLogType &&
                    formik.errors.qrAttendanceLogType}
                </div>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="mb-3">
              <label className="required fw-bold mb-2.5 block text-black dark:text-white">
                {intl.formatMessage({
                  id: "MODULE.SETTING.GENRAL.MINPRODUCTNOTIFY",
                })}
              </label>
              <input
                type="number"
                name="minSuppProductNotify"
                className="form-control"
                placeholder={intl.formatMessage({
                  id: "MODULE.SETTING.GENRAL.MINPRODUCTNOTIFY",
                })}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.minSuppProductNotify}
              />

              {formik.errors.minSuppProductNotify && (
                <div className="error mt-2">
                  {formik.touched.minSuppProductNotify &&
                    formik.errors.minSuppProductNotify}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-md-6">
            <div className="mb-10">
              <label className="required form-label">
                {intl.formatMessage({
                  id: "MODULE.SETTING.GENRAL.INVOICENOTES",
                })}
              </label>
              <textarea
                name="InvoiceNotes"
                className="form-control"
                placeholder={intl.formatMessage({
                  id: "PLACEHOLDER.INOVICENOTES",
                })}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.InvoiceNotes}
                rows={4}
              />

              {formik.errors.InvoiceNotes && (
                <div className="error mt-2">
                  {formik.touched.InvoiceNotes && formik.errors.InvoiceNotes}
                </div>
              )}
            </div>
          </div>

          <div className="col-md-6">
            <div className="mb-3">
              <label htmlFor="suppInvocieNotes" className="required form-label">
                {intl.formatMessage({
                  id: "MODULE.SETTING.GENRAL.SUPPLYMENTNOTES",
                })}
              </label>

              <textarea
                id="suppInvocieNotes"
                name="suppInvocieNotes"
                className="form-control"
                placeholder={intl.formatMessage({
                  id: "PLACEHOLDER.SOPNOTES",
                })}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.suppInvocieNotes}
                rows={4}
              />
              {formik.errors.suppInvocieNotes && (
                <div className="error mt-2">
                  {formik.touched.suppInvocieNotes &&
                    formik.errors.suppInvocieNotes}
                </div>
              )}
            </div>
          </div>
        </div>

        <button type="submit" className="btn btn-primary btn-block m-4">
          {intl.formatMessage({
            id: "MODULE.PACKAGEPERIOD.SAVEBUTTON",
          })}
        </button>
      </Form>
    </div>
  );
};

export default GenralSetting;
