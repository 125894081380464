import { useEffect, useState, useRef } from "react";
import { Modal } from "react-bootstrap";
import { useIntl } from "react-intl";
import Select from "react-select";
import {
  addEmployeeAttandanceByQr,
  addMemberAttandanceByQR,
  getActiveLogType,
} from "../redux/HeadersCRUD";
import swal from "sweetalert";

const AddAttandanceModal = ({ show, setShow, scanData, onSuccess }: any) => {
  const intl = useIntl();
  const [selectLog, setSelectLog] = useState(null);
  const [logTypeOpt, setLogTypeOpt] = useState([]);
  const isMounted = useRef(false); // Ref to track component mount status

  const userType = JSON.parse(localStorage?.getItem("activeUserType") || "{}");
  const activeBranch = JSON.parse(localStorage.getItem("ActiveBranch") || "{}");
  const ActiveBranchUUID =
    userType === "member"
      ? activeBranch?.[0]?.iBranchUUID
      : activeBranch?.iBranchUUID;
  const MemberProfile = JSON.parse(localStorage?.getItem("activeUser") || "{}");
  const MemberID = MemberProfile?.member_id;
  const EMPProfile = JSON.parse(
    localStorage?.getItem("employeeDetails") || "{}"
  );
  const EmpID = EMPProfile?.employee_id;

  const handleClose = () => {
    setShow(false);
    handleReset();
    onSuccess();
  };

  useEffect(() => {
    isMounted.current = true; // Component is mounted
    if (show) {
      GetActiveLogType(ActiveBranchUUID);
    } else {
      return () => {
        isMounted.current = false; // Component will unmount
      };
    }
  }, [show]);

  const GetActiveLogType = async (branchUUID: any) => {
    try {
      const response = await getActiveLogType(branchUUID);
      if (isMounted.current) {
        setLogTypeOpt(response?.data?.data || []);
      }
    } catch (error) {
      console.error("Error fetching log types:", error);
    }
  };

  const handleLogType = async (selectedOption: any) => {
    setSelectLog(selectedOption);
    if (selectedOption?.value) {
      console.log(scanData, "scandata");
      const formData: any = {
        branchID: scanData?.branchID,
        logType: selectedOption?.value,
      };
      try {
        let response;
        if (userType === "member") {
          formData.memberID = MemberID;
          response = await addMemberAttandanceByQR(formData);
        } else if (userType === "employee") {
          formData.employeeID = EmpID;
          response = await addEmployeeAttandanceByQr(formData);
        }
        if (response?.status === 200) {
          swal(response?.data?.replyMsg, { icon: "success" });
          handleClose();
        } else {
          swal(response?.data?.replyMsg, { icon: "error" });
        }
      } catch (error) {
        console.error("Error submitting attendance:", error);
        swal("An error occurred. Please try again.", { icon: "error" });
      }
    }
  };

  const handleReset = () => {
    setSelectLog(null);
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>
          {intl.formatMessage({ id: "CHOOSE.LOGTYPE" })}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body scroll-y mx-2 mx-xl-5 my-2">
        <div className="row">
          <div className="mb-2">
            <label className="form-label">
              {" "}
              {intl.formatMessage({ id: "LOGTYPE.NAME" })}
            </label>
            <Select
              name="logType"
              className="basic-single"
              value={selectLog}
              onChange={handleLogType}
              options={logTypeOpt?.map((item: any) => ({
                value: item?.logType,
                label: item?.logType,
              }))}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddAttandanceModal;
