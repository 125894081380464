import { Action } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
// import InvoiceList from "../InvoiceList";

export interface ActionWithPayload<T> extends Action {
  payload?: T;
}

export const actionTypes = {
  GetQrMemberLogs: "[GetQrMemberLogs] Action",
};

const initialAuthState: ICenterState = {
  Qrmemberlogs: undefined,
};

export interface ICenterState {
  Qrmemberlogs?: any;
}

export const reducer = persistReducer(
  { storage, key: "v100-demo1-auth", blacklist: ["Qrmemberlogs"] },
  (
    state: ICenterState = initialAuthState,
    action: ActionWithPayload<ICenterState>
  ) => {
    switch (action.type) {
      case actionTypes.GetQrMemberLogs: {
        const Qrmemberlogs = action.payload?.Qrmemberlogs;
        return { ...state, Qrmemberlogs };
      }
      default:
        return state;
    }
  }
);

export const actions = {
  GetQrMemberLogs: (Qrmemberlogs: string) => ({
    type: actionTypes.GetQrMemberLogs,
    payload: { Qrmemberlogs },
  }),
};
