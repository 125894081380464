import React, { useEffect, useState } from "react";
import "./setting.css";
import { Form } from "react-bootstrap";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import {
  UpdateCompany,
  currencyDataApi,
  detailsOfCompanyApi,
} from "../redux/settingCRUD";
import { useFormik } from "formik";
import Select from "react-select";
import swal from "sweetalert";
import SMS from "./SMS";
import Whatsapp from "./Whatsapp";
import Smtp from "./Smtp";
import GenralSetting from "./GenralSetting";
import Biometric from "./Biometric";
const data: any = localStorage.getItem("CountryCurrencycode");
const CountryCode = JSON.parse(data);
const Setting = ({ user }: any) => {
  const intl = useIntl();
  const { branchID, companyUUID }: any = useParams();
  const [UpdateUser, setUpdateUser] = useState<any>();
  const [smsdata, setsmsData] = useState<any>();
  const [whatsappData, setWhatsappData] = useState<any>();
  const [smtpData, setSmtpData] = useState<any>();
  const [genralSetting, setGenralSetting] = useState<any>();
  const [successResponse, setSuccessResponse] = useState<any>(false);
  const [errorResponse, setErrorResponse] = useState<any>(false);
  const [err, setErr] = useState<any>([]);
  const [currency, setCurrency] = useState<any>();
  let selectedBranch: any = localStorage.getItem("ActiveBranch");
  let user1: any = localStorage.getItem("activeUserType");
  let userType: any = JSON.parse(user1);
  const ActiveBranch = JSON.parse(selectedBranch);
  const ActiveBranchUUID = ActiveBranch.iBranchUUID;
  const ActiveBranchID = ActiveBranch.iBranchID;
  const ActiveBranchSettingUUID = ActiveBranch.iBranchSettingUUID;

  //   const [selectedEnqData, setSelectedEnqData] = useState<any>({});

  const initialState = {
    compnayUUID: companyUUID,
    branchID: branchID,
    companyName: UpdateUser?.vCompanyName,
    centerName: UpdateUser?.centerName,
    email: UpdateUser?.vEmail,
    countryCode: UpdateUser?.vCountryCode,
    mobileNo: UpdateUser?.vPhoneNo,
    website: UpdateUser?.vWebsiteName,
    hsnNo: UpdateUser?.vHsnCode,
    address: UpdateUser?.tAddress,
    taxNo: UpdateUser?.vGstNo,
    companyPhoto: null,
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialState,

    // validationSchema: Schemas,

    onSubmit: async (values, action) => {
      // console.log(values, "valee");
      const formData = new FormData();
      formData.append("compnayUUID", values.compnayUUID);
      formData.append("branchID", values.branchID);
      formData.append("companyName", values.companyName);
      formData.append("centerName", values.centerName);
      formData.append("email", values.email);
      formData.append("countryCode", values.countryCode);
      formData.append("mobileNo", values.mobileNo);
      formData.append("website", values.website);
      formData.append("hsnNo", values.hsnNo);
      formData.append("address", values.address);
      formData.append("taxNo", values.taxNo);
      if (values.companyPhoto !== null) {
        formData.append("companyPhoto", values.companyPhoto);
      }
      const response = await UpdateCompany(formData);
      if (response.status == 200) {
        swal(response?.data?.replyMsg, { icon: "success" });
      }
    },
  });
  useEffect(() => {
    if (userType !== "member") {
      detailsCompany();
      currencyData();
    }
  }, [companyUUID]);

  const currencyData = async () => {
    const response = await currencyDataApi();
    // console.log(response, "dataaa");
    setCurrency(response?.data?.data);
  };
  const detailsCompany = async () => {
    const respones = await detailsOfCompanyApi(ActiveBranchUUID);
    // console.log("response", respones);
    setUpdateUser(respones?.data?.data);
    localStorage.setItem("ActiveBranch", JSON.stringify(respones?.data?.data));
    // setIMageURl(respones?.data?.imageBaseURL);
    // setIMageName(respones?.data?.data?.vImageName);

    // console.log(respones?.data?.data?.vImageName, "respones");
  };

  return (
    <>
      <div className="container form">
        {/* <Form onSubmit={formik.handleSubmit} className="form"> */}
        {/* <div className="d-flex menu">
            <div
              className="px-5 py-2 fs-6 menu-item"
              onClick={() => setIsSms(!isSms)}
            >
              {`${intl.formatMessage({ id: "MODULE.SETTING.SMS" })}`}
            </div>
            <div
              className="px-5 py-2 fs-6 menu-item"
              onClick={() => setIsWhastapp(!isWhatsapp)}
            >
              {" "}
              {`${intl.formatMessage({ id: "MODULE.SETTING.WHATSAPP" })}`}
            </div>
            <div
              className="px-5 py-2 fs-6 menu-item"
              onClick={() => setIsSmtp(!isSmtp)}
            >
              {" "}
              {`${intl.formatMessage({ id: "MODULE.SETTING.SMTP" })}`}
            </div>
          </div> */}

        <ul className="nav nav-tabs nav-line-tabs mb-5 fs-6 py-5 gap-3">
          <li className="nav-item">
            <a
              className="nav-link active btn btn-sm btn-flex btn-light btn-active-primary fw-bolder"
              data-bs-toggle="tab"
              href="#kt_tab_pane_1"
            >
              {intl.formatMessage({ id: "MODULE.SETTING.SMS" })}
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link btn btn-sm btn-flex btn-light btn-active-primary fw-bolder"
              data-bs-toggle="tab"
              href="#kt_tab_pane_2"
            >
              {intl.formatMessage({ id: "MODULE.SETTING.WHATSAPP" })}
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link btn btn-sm btn-flex btn-light btn-active-primary fw-bolder"
              data-bs-toggle="tab"
              href="#kt_tab_pane_3"
            >
              {intl.formatMessage({ id: "MODULE.SETTING.SMTP" })}
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link btn btn-sm btn-flex btn-light btn-active-primary fw-bolder"
              data-bs-toggle="tab"
              href="#kt_tab_pane_4"
            >
              {intl.formatMessage({ id: "MODULE.SETTING.GENRAL" })}
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link btn btn-sm btn-flex btn-light btn-active-primary fw-bolder"
              data-bs-toggle="tab"
              href="#kt_tab_pane_5"
            >
              {intl.formatMessage({ id: "MODULE.SETTING.BIOMETRIC" })}
            </a>
          </li>
        </ul>

        {successResponse && (
          <div className="alert alert-dismissible bg-light-success d-flex flex-column flex-sm-row p-5 mb-10">
            <div className="d-flex flex-column text-success pe-0 pe-sm-10">
              <h5 className="mb-1">
                {intl.formatMessage({ id: "MODULE.SETTING.success" })}
              </h5>
              <span>
                {intl.formatMessage({ id: "MODULE.SETTING.UpdateSucess" })}
              </span>
            </div>

            <button
              type="button"
              className="position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto"
              onClick={() => setSuccessResponse(false)}
            >
              <span className="svg-icon svg-icon-1 svg-icon-success">X</span>
            </button>
          </div>
        )}
        {errorResponse && (
          <div className="alert alert-dismissible bg-light-danger d-flex flex-column flex-sm-row p-5 mb-10">
            <div className="d-flex flex-column text-danger pe-0 pe-sm-10">
              <h5 className="mb-1 text-danger">
                {intl.formatMessage({ id: "MODULE.SETTING.error" })}
              </h5>
              {err && <span>{err}</span>}
              {Array.isArray(err) &&
                err?.map((item: any) => <span>* {Object.values(item)}</span>)}
            </div>

            <button
              type="button"
              className="position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto"
              onClick={() => {
                setErrorResponse(false);
                setErr("");
              }}
            >
              <span className="svg-icon svg-icon-1 svg-icon-danger">X</span>
            </button>
          </div>
        )}
        <div className="tab-content" id="myTabContent">
          <div
            className="tab-pane fade active show"
            id="kt_tab_pane_1"
            role="tabpanel"
          >
            <SMS
              smsdata={smsdata}
              setsmsData={setsmsData}
              UpdateUser={UpdateUser}
              ActiveBranchUUID={ActiveBranchUUID}
              ActiveBranchID={ActiveBranchID}
              detailsCompany={detailsCompany}
              setSuccessResponse={setSuccessResponse}
              setErrorResponse={setErrorResponse}
              setErr={setErr}
              ActiveBranchSettingUUID={ActiveBranchSettingUUID}
            />
          </div>
          <div className="tab-pane fade" id="kt_tab_pane_2" role="tabpanel">
            <Whatsapp
              whatsappData={whatsappData}
              setWhatsappData={setWhatsappData}
              UpdateUser={UpdateUser}
              ActiveBranchUUID={ActiveBranchUUID}
              ActiveBranchID={ActiveBranchID}
              detailsCompany={detailsCompany}
              setSuccessResponse={setSuccessResponse}
              setErrorResponse={setErrorResponse}
              setErr={setErr}
              ActiveBranchSettingUUID={ActiveBranchSettingUUID}
            />
          </div>
          <div className="tab-pane fade" id="kt_tab_pane_3" role="tabpanel">
            <Smtp
              UpdateUser={UpdateUser}
              setSmtpData={setSmtpData}
              ActiveBranchUUID={ActiveBranchUUID}
              ActiveBranchID={ActiveBranchID}
              detailsCompany={detailsCompany}
              setSuccessResponse={setSuccessResponse}
              setErrorResponse={setErrorResponse}
              setErr={setErr}
              ActiveBranchSettingUUID={ActiveBranchSettingUUID}
            />
          </div>
          <div className="tab-pane fade" id="kt_tab_pane_4" role="tabpanel">
            <GenralSetting
              UpdateUser={UpdateUser}
              currency={currency}
              setGenralSetting={setGenralSetting}
              ActiveBranchUUID={ActiveBranchUUID}
              ActiveBranchID={ActiveBranchID}
              detailsCompany={detailsCompany}
              setSuccessResponse={setSuccessResponse}
              setErrorResponse={setErrorResponse}
              setErr={setErr}
              ActiveBranchSettingUUID={ActiveBranchSettingUUID}
            />
          </div>
          <div className="tab-pane fade" id="kt_tab_pane_5" role="tabpanel">
            <Biometric
              whatsappData={whatsappData}
              setWhatsappData={setWhatsappData}
              UpdateUser={UpdateUser}
              ActiveBranchUUID={ActiveBranchUUID}
              ActiveBranchID={ActiveBranchID}
              detailsCompany={detailsCompany}
              setSuccessResponse={setSuccessResponse}
              setErrorResponse={setErrorResponse}
              setErr={setErr}
            />
          </div>
        </div>

        {/* <button type="submit" className="btn btn-primary btn-block mt-4">
          {intl.formatMessage({
            id: "MODULE.MEMBER.UPDATE",
          })}
        </button> */}
        {/* </Form> */}
      </div>
    </>
  );
};

export default Setting;
