import { Action } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export interface ActionWithPayload<T> extends Action {
  payload?: T;
}

export const actionTypes = {
  GetCustomer: "[GetCustomer] Action",
};

const initialAuthState: ICenterState = {
  customer: undefined,
};

export interface ICenterState {
  customer?: any;
}

export const reducer = persistReducer(
  { storage, key: "v100-demo1-auth", blacklist: ["customer"] },
  (
    state: ICenterState = initialAuthState,
    action: ActionWithPayload<ICenterState>
  ) => {
    switch (action.type) {
      case actionTypes.GetCustomer: {
        const customer = action.payload?.customer;
        return { ...state, customer };
      }
      default:
        return state;
    }
  }
);

export const actions = {
  GetCustomer: (customer: string) => ({
    type: actionTypes.GetCustomer,
    payload: { customer },
  }),
};
