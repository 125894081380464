import { Action } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export interface ActionWithPayload<T> extends Action {
  payload?: T;
}

export const actionTypes = {
  GetWorkOut: "[GetWorkOut] Action",
};

const initialAuthState: ICenterState = {
  workouts: undefined,
};

export interface ICenterState {
  workouts?: any;
}

export const reducer = persistReducer(
  { storage, key: "v100-demo1-auth", blacklist: ["workouts"] },
  (
    state: ICenterState = initialAuthState,
    action: ActionWithPayload<ICenterState>
  ) => {
    switch (action.type) {
      case actionTypes.GetWorkOut: {
        const workouts = action.payload?.workouts;
        return { ...state, workouts };
      }
      default:
        return state;
    }
  }
);

export const actions = {
  GetWorkOut: (workouts: string) => ({
    type: actionTypes.GetWorkOut,
    payload: { workouts },
  }),
};
