/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { PageTitle } from "../../../_metronic/layout/core";
import "rsuite/DateRangePicker/styles/index.css";
import DateRangePicker from "rsuite/DateRangePicker";

import { getDashboard } from "./redux/dashboardCRUD";
import { Button, Col, Container, Row, Form, Card } from "react-bootstrap";
import DashboardCard from "./DashboardCard";
import { useFormik } from "formik";
import moment from "moment";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "../../../setup";

const DashboardMember = () => {
  const intl = useIntl();

  const user: any = useSelector<RootState>(
    ({ auth }) => auth.user,
    shallowEqual
  ) as any;
  // const memberdetails: any = localStorage.getItem("persist:v100-demo1-auth");
  // const memberLogin = JSON.parse(memberdetails);
  // const member1 = JSON.parse(memberLogin?.user);
  console.log(user, "hdsafjlkds");
  const admin: any = localStorage.getItem("ActiveBranch");
  const abranch = JSON.parse(admin);
  const activebranchUUID = abranch?.iBranchUUID;
  const memberuuid = user?.member_uuid;
  const dateFormat = abranch?.idateFormate || "YYYY-MM-DD";
  const currency = abranch?.currencySymbol || "₹";

  const date = moment().format(dateFormat);
  const defaultdate = new Date();

  const [value, setValue] = useState<any>([defaultdate, defaultdate]);
  const [apiresponse, setapiresponse] = useState<any>();
  const [isUpdated, setIsUpdated] = useState<any>(false);

  const initialState = {
    startDate: date,
    endDate: date,
  };
  const [filterValues, setFilterValues] = useState(initialState);

  const dashboard = async () => {
    const queryString = new URLSearchParams(filterValues).toString();

    const response = await getDashboard(memberuuid);
    console.log(response?.data?.data, "dashboard");
    if (response?.status === 200) {
      // setIsUpdated(true);
      setapiresponse(response?.data?.data);
    } else {
      console.log(response?.data?.replyMsg);
    }
  };

  // const formik = useFormik({
  //   enableReinitialize: true,
  //   initialValues: filterValues,
  //   onSubmit: (values) => {
  //     console.log(values, "date");
  //     setFilterValues(values);
  //   },
  // });

  // const handleDateReset = (values: any) => {
  //   setValue([defaultdate, defaultdate]);
  //   formik.setFieldValue("startDate", date);
  //   formik.setFieldValue("endDate", date);
  //   // setIsUpdated(false);
  // };

  // const handleChange = (values: any) => {
  //   setValue(values);
  //   // console.log(values,)
  //   if (values && values.length !== 0) {
  //     formik.setFieldValue("startDate", moment(values[0]).format(dateFormat));
  //     formik.setFieldValue("endDate", moment(values[1]).format(dateFormat));
  //   }
  // };

  // const handleReset = () => {
  //   setValue([defaultdate, defaultdate]);
  //   formik.resetForm();
  //   // handleResetFilter();
  //   setFilterValues(initialState);
  //   // setIsUpdated(false);
  //   // getMembers();
  // };

  useEffect(() => {
    dashboard();
  }, [filterValues]);

  const enrolled = intl.formatMessage({ id: "DASHBOARD.MEMBER.ENROLLED" });
  const reciept = intl.formatMessage({ id: "DASHBOARD.MEMBER.RECIEPT" });
  const suppliment = intl.formatMessage({ id: "DASHBOARD.MEMBER.POS" });
  const dueamt = intl.formatMessage({ id: "DASHBOARD.MEMBER.DUEAMT" });
  // const enquiry = intl.formatMessage({ id: "DASHBOARD.ENQUIRY" });
  // const member = intl.formatMessage({ id: "DASHBOARD.MEMBER" });
  // const expiry = intl.formatMessage({ id: "DASHBOARD.EXPIRY" });
  // const followup = intl.formatMessage({ id: "DASHBOARD.FOLLOWUP" });
  // const enrolled = intl.formatMessage({ id: "DASHBOARD.ENROLLED" });
  // const employee = intl.formatMessage({ id: "DASHBOARD.EMPLOYEE" });
  // const empbirth = intl.formatMessage({ id: "DASHBOARD.EMPLOYEEBIRTHDAY" });
  // const membirth = intl.formatMessage({ id: "DASHBOARD.MEMBERBIRTHDAY" });
  // const invoice = intl.formatMessage({ id: "DASHBOARD.INVOICE" });
  // const dueamount = intl.formatMessage({ id: "DASHBOARD.DUEAMOUNT" });
  // const expense = intl.formatMessage({ id: "DASHBOARD.EXPENSE" });
  // const Today = intl.formatMessage({ id: "DASHBOARD.TODAY" });
  // const between = intl.formatMessage({ id: "DASHBOARD.BETWEEN" });
  // const total = intl.formatMessage({ id: "DASHBOARD.TOTAL" });

  return (
    <>
      {" "}
      <Container fluid className="rounded" style={{ background: "#FFFFFF" }}>
        {/* <Card className="w-100">
          <Card.Body className="py-3">
            <Form onSubmit={formik.handleSubmit}>
              <Row className="my-4">
                {" "}
                <Col md={3} className="mb-3">
                  <div className="mb-3">
                    <label htmlFor="gender" className="form-label">
                      {intl.formatMessage({
                        id: "MODULE.MEMBERS.DATERANGE",
                      })}
                    </label>

                    <DateRangePicker
                      value={value}
                      onChange={handleChange}
                      format="MM/dd/yyyy"
                      placeholder="Select Date Range"
                      placement="right"
                      style={{ margin: "2px" }}
                      onClean={handleDateReset}
                    />
                  </div>
                </Col>
                <Col
                  md={4}
                  className="d-flex justify-content-start align-items-center"
                >
                  <Button
                    type="submit"
                    variant="primary"
                    className="me-3 mr-4 "
                    // onClick={(e) => handleSearchClick(e)}
                    // disabled={isLoading}
                  >
                    {intl.formatMessage({
                      id: "COMMON.APPLY",
                    })}
                  </Button>
                  <Button
                    variant="secondary"
                    onClick={handleReset}
                    className="me-3 "
                  >
                    {intl.formatMessage({
                      id: "COMMON.RESET",
                    })}
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card> */}
        <Row className="my-4 py-4 ">
          <Col md={3}>
            <div className="bg-light-warning py-5 px-2  rounded-2  mb-7 ">
              <DashboardCard
                title=""
                date={enrolled}
                subtitle={apiresponse?.totalEnrolledPackage}
                color="warning"
                slug="Enrolled packages "
              />
            </div>
          </Col>
          <Col md={3}>
            <div className="bg-light-primary  py-5 px-2  rounded-2  mb-7 ">
              <DashboardCard
                title=""
                date={dueamt}
                subtitle={apiresponse?.totalFixedDueAmt}
                color="primary"
                slug="Enquiries "
              />
            </div>
          </Col>
          <Col md={3}>
            <div className="bg-light-warning py-5 px-2  rounded-2  mb-7 ">
              <DashboardCard
                title=""
                date={reciept}
                subtitle={apiresponse?.totalReceipt}
                color="warning"
                slug="Follouw-ups "
              />
            </div>
          </Col>
          <Col md={3}>
            <div className="bg-light-success py-5 px-2  rounded-2  mb-7 ">
              <DashboardCard
                title=" "
                date={suppliment}
                subtitle={apiresponse?.totalpurchase}
                color="success"
                slug="Enrolled Packages"
              />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default DashboardMember;
