const StatusBadge = (status: any) => {
  switch (status) {
    case 0:
      return (
        <div className="badge badge-light-warning fw-bolder">Inactive</div>
      );
    case 1:
      return <div className="badge badge-light-success fw-bolder">Active</div>;
    case 2:
      return <div className="badge badge-light-danger fw-bold">Delete</div>;
    case "Inactive":
      return (
        <div className="badge badge-light-warning fw-bolder">Inactive</div>
      );
    case "Active":
      return <div className="badge badge-light-success fw-bolder">Active</div>;
    case "open":
      return <div className="badge badge-light-warning fw-bolder">open</div>;
    case "In progress":
      return (
        <div className="badge badge-light-teal  fw-bolder">In progress</div>
      );
    case "Pending":
      return <div className="badge badge-light-orange  fw-bolder">Pending</div>;
    case "Rejected":
      return (
        <div className="badge badge-light-danger  fw-bolder">Rejected </div>
      );
    case "Resolved":
      return (
        <div className="badge badge-light-success  fw-bolder">Resolved </div>
      );
    case "Closed":
      return <div className="badge badge-light-cyan  fw-bolder">Closed </div>;
    default:
      return null;
  }
};

const PriorityBadge = (status: String) => {
  switch (status) {
    case "Low":
      return <div className="badge badge-light-success fw-bold">Low</div>;
    case "Medium":
      return <div className="badge badge-light-warning fw-bold">Medium</div>;
    case "High":
      return <div className="badge badge-light-danger fw-bold">Hign</div>;
    default:
      return null;
  }
};
const WeekBADGES = (status: String, big: any) => {
  const result = status.toLowerCase();
  switch (result) {
    case "sunday":
      return (
        <div
          className={`badge badge-light-success fw-bold ${big ? "fs-5" : ""}`}
        >
          Sunday
        </div>
      );
    case "monday":
      return (
        <div
          className={`badge badge-light-warning fw-bold ${big ? "fs-5" : ""}`}
        >
          Monday
        </div>
      );
    case "tuesday":
      return (
        <div
          className={`badge badge-light-danger fw-bold ${big ? "fs-5" : ""}`}
        >
          Tuesday
        </div>
      );
    case "wednesday":
      return (
        <div
          className={`badge badge-light-primary fw-bold ${big ? "fs-5" : ""}`}
        >
          Wednesday
        </div>
      );
    case "thursday":
      return (
        <div className={`badge badge-light-info fw-bold ${big ? "fs-5" : ""}`}>
          Thursday
        </div>
      );
    case "friday":
      return (
        <div className={`badge badge-secondary fw-bold ${big ? "fs-5" : ""}`}>
          Friday
        </div>
      );
    case "saturday":
      return (
        <div className={`badge badge-dark fw-bold ${big ? "fs-5" : ""}`}>
          Saturday
        </div>
      );
    default:
      return null;
  }
};

const TicketBadge = (status: String) => {
  switch (status) {
    case "open":
      return (
        <div className="text-capitalize badge badge-light-primary fw-bold">
          {status}
        </div>
      );
    case "customer-reply":
      return (
        <div className="text-capitalize badge badge-light-info fw-bold">
          {status}
        </div>
      );
    case "answered":
      return (
        <div className=" text-capitalize badge badge-light-success fw-bold">
          {status}
        </div>
      );
    case "Re-open":
      return (
        <div className="text-capitalize badge badge-light-warning fw-bold">
          {status}
        </div>
      );
    case "closed":
      return (
        <div className="text-capitalize badge badge-light-danger fw-bold">
          {status}
        </div>
      );
    default:
      return null;
  }
};
const PlanCenterBadge = (status: String) => {
  switch (status) {
    case "fitnessCenter":
      return (
        <div className="badge badge-light-success fw-bold">fitnesscenter</div>
      );
    case "channelPartner":
      return (
        <div className="badge badge-light-primary fw-bold">channelpartner</div>
      );
    default:
      return null;
  }
};

const DateFormate = (date: any) => {
  return new Date(date).toLocaleString("en-US", {
    dateStyle: "medium",
    timeStyle: "short",
  });
};

const PlanAmountStatusBadge = (status: any) => {
  switch (status) {
    case 0:
      return <div className="badge badge-light-danger fw-bold">Unpaid</div>;
    case 1:
      return <div className="badge badge-light-primary fw-bold">Paid</div>;
    default:
      return null;
  }
};

const MoneyRequestStatusBadge = (status: any) => {
  switch (status) {
    case "Requested":
      return <div className="badge badge-light-warning fw-bold">Requested</div>;
    case "approved":
      return <div className="badge badge-light-success fw-bold">approved</div>;
    case "unapproved":
      return <div className="badge badge-light-danger fw-bold">unapproved</div>;
    case "pending":
      return <div className="badge badge-light-primary fw-bold">pending</div>;
    default:
      return null;
  }
};

export {
  StatusBadge,
  PriorityBadge,
  DateFormate,
  PlanCenterBadge,
  PlanAmountStatusBadge,
  TicketBadge,
  MoneyRequestStatusBadge,
  WeekBADGES,
};
