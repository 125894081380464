// AxiosSetupComponent.tsx
import axios from "axios";
import setupAxios from "./SetupAxios";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import { useStore } from "react-redux";
import * as _redux from "../../setup";
import { useLang } from "../../_metronic/i18n/Metronici18n";

const AxiosSetupComponent = () => {
  const history = useHistory(); // Get history from React Router v5
  const store = useStore(); // Get the Redux store
  const lang = useLang();

  useEffect(() => {
    setupAxios(axios, store, history, lang); // Initialize Axios with interceptors
  }, [store, history, lang]);

  return null; // This component doesn't render anything
};

export default AxiosSetupComponent;
