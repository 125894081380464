import { useEffect, useState } from "react";
import { KTSVG } from "../../../helpers";
import { GetReminderList } from "../../../../app/modules/quickLinks/modules/activity/redux/ActivityCRUD";

const showPagelimit = process.env.REACT_APP_PAGELIMIT;
const finalPageLimitArray: any = showPagelimit?.split(",");
const pageDefaultValue = Number(finalPageLimitArray[0]);

const ActivityLOGs = () => {
  const [logs, setlogs] = useState<any>([]);

  let us: any = localStorage?.getItem("activeUserType");
  const userType = JSON.parse(us);
  let Activemode: any = localStorage?.getItem("activePlanModules");
  const activemodules = JSON.parse(Activemode);
  let selectedBranch: any = localStorage?.getItem("ActiveBranch");
  const ActiveBranch = JSON.parse(selectedBranch);
  const data = {
    status: "",
    branchID: ActiveBranch?.iBranchID,
    page_limit: pageDefaultValue,
    page_no: "1",
    search: "",
    start_date: "",
    end_date: "",
    order_key: "dtModified_Date",
    order_value: "desc",
  };
  const GetActivity = async () => {
    try {
      // Check if activemodules is an array
      if (Array.isArray(activemodules)) {
        // Find the item with GroupSlug as 'quicklinks'
        const isAUTH = activemodules.find((item: any) => {
          return item?.GroupSlug === "quicklinks";
        });
        // console.log(isAUTH);
        // If isAUTH contains modulesdata as an array, make API call
        if (isAUTH?.modulesdata?.length > 0) {
          if (userType !== "member") {
            const response = await GetReminderList(data); // Call your API to get reminder list
            if (response?.status === 200) {
              setlogs(response?.data?.data);
            }
          }
        }
      } else {
        console.log("No modulesdata found for quicklinks");
      }
      // } else {
      //   console.error("activemodules is not an array");
      // }
    } catch (error) {
      console.error("Error fetching activity:", error); // Error handling
    }
  };
  useEffect(() => {
    GetActivity();
  }, []);

  return (
    <>
      {logs && logs.length === 0 ? (
        <div>no activities yet</div>
      ) : (
        logs?.map((item: any) => (
          <div className="timeline-item w-100">
            <div className="timeline-line w-40px"></div>
            <div className="timeline-icon symbol symbol-circle symbol-40px me-4">
              {" "}
              <div className="symbol-label bg-light">
                <KTSVG
                  path="/media/icons/duotune/general/gen002.svg"
                  className="svg-icon-2 svg-icon-gray-500"
                />
              </div>
            </div>
            <div>
              <div className="fw-bold fs-3">
                <span>{item?.title}</span>
              </div>
              <div className="fs-6">
                <span>{item?.description}</span>
              </div>
              <div className="s">
                <span className="text-muted me-2 fs-7">
                  {item?.dtCreated_Date?.split("T")[0]}
                </span>
              </div>
            </div>
          </div>
        ))
      )}
    </>
  );
};

export default ActivityLOGs;

/* <div className="timeline-item w-100">
<div className="timeline-line w-40px"></div>
<div className="timeline-icon symbol symbol-circle symbol-40px me-4">
  <div className="symbol-label bg-light">
    <KTSVG
      path="/media/icons/duotune/communication/com003.svg"
      className="svg-icon-2 svg-icon-gray-500"
    />
  </div>
</div>
<div className="timeline-content mb-10 mt-n1">
  <div className="pe-3 mb-5">
    <div className="fs-5 fw-bold mb-2">
      There are 2 new tasks for you in “AirPlus Mobile APp” project:
    </div>

    <div className="d-flex align-items-center mt-1 fs-6">
      <div className="text-muted me-2 fs-7">
        Added at 4:23 PM by
      </div>

      <div
        className="symbol symbol-circle symbol-25px"
        data-bs-toggle="tooltip"
        data-bs-boundary="window"
        data-bs-placement="top"
        title="Nina Nilson"
      >
        <img
          src={toAbsoluteUrl("/media/avatars/150-11.jpg")}
          alt="img"
        />
      </div>
    </div>
  </div>
</div>{" "}
</div> */
