/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { PageTitle } from "../../../_metronic/layout/core";
import "rsuite/DateRangePicker/styles/index.css";
import DateRangePicker from "rsuite/DateRangePicker";

import { getDashboard } from "./redux/dashboardCRUD";
import { Button, Col, Container, Row, Form, Card } from "react-bootstrap";
import DashboardCard from "./DashboardCard";
import { useFormik } from "formik";
import moment from "moment";
import DashboardMember from "../../memberModules/Memberdashboard/DashboardMember";
import EnquiryModal from "./enquirymodal/EnquiryModal";
import { title } from "process";
import { Link } from "react-router-dom";

// import {
//   MixedWidget2,
//   MixedWidget10,
//   MixedWidget11,
//   ListsWidget2,
//   ListsWidget3,
//   ListsWidget4,
//   ListsWidget5,
//   ListsWidget6,
//   TablesWidget5,
//   TablesWidget10,
//   MixedWidget8,
// } from "../../../_metronic/partials/widgets";

const DashboardPage: FC = () => {
  const intl = useIntl();
  const admin: any = localStorage.getItem("ActiveBranch");
  const abranch = JSON.parse(admin);
  const activebranchUUID = abranch?.iBranchUUID;
  const dateFormat = abranch?.idateFormate || "YYYY-MM-DD";
  const senddateFormat = "YYYY-MM-DD";
  const currency = abranch?.currencySymbol || "₹";

  const date = moment().format(senddateFormat);
  const defaultdate = new Date();

  const [value, setValue] = useState<any>([defaultdate, defaultdate]);
  const [apiresponse, setapiresponse] = useState<any>();
  const [isUpdated, setIsUpdated] = useState<any>(false);

  const initialState = {
    startDate: date,
    endDate: date,
  };
  const [filterValues, setFilterValues] = useState(initialState);

  const dashboard = async () => {
    const queryString = new URLSearchParams(filterValues).toString();
    const response = await getDashboard(activebranchUUID, queryString);
    // console.log(response?.data?.data, "dashboard");
    if (response?.status === 200) {
      // setIsUpdated(true);
      setapiresponse(response?.data?.data);
    } else {
      console.log(response?.data?.replyMsg);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: filterValues,
    onSubmit: (values) => {
      // console.log(values, "date");
      setFilterValues(values);
    },
  });

  const handleDateReset = (values: any) => {
    setValue([defaultdate, defaultdate]);
    formik.setFieldValue("startDate", date);
    formik.setFieldValue("endDate", date);
    // setIsUpdated(false);
  };

  const handleChange = (values: any) => {
    setValue(values);
    // console.log(values,)
    if (values && values.length !== 0) {
      formik.setFieldValue(
        "startDate",
        moment(values[0]).format(senddateFormat)
      );
      formik.setFieldValue("endDate", moment(values[1]).format(senddateFormat));
    }
  };

  const handleReset = () => {
    setValue([defaultdate, defaultdate]);
    formik.resetForm();
    // handleResetFilter();
    setFilterValues(initialState);
    // setIsUpdated(false);
    // getMembers();
  };

  useEffect(() => {
    dashboard();
  }, [filterValues]);

  const enquiry = intl.formatMessage({ id: "DASHBOARD.ENQUIRY" });
  const member = intl.formatMessage({ id: "DASHBOARD.MEMBER" });
  const expiry = intl.formatMessage({ id: "DASHBOARD.EXPIRY" });
  const followup = intl.formatMessage({ id: "DASHBOARD.FOLLOWUP" });
  const enrolled = intl.formatMessage({ id: "DASHBOARD.ENROLLED" });
  const employee = intl.formatMessage({ id: "DASHBOARD.EMPLOYEE" });
  const empbirth = intl.formatMessage({ id: "DASHBOARD.EMPLOYEEBIRTHDAY" });
  const membirth = intl.formatMessage({ id: "DASHBOARD.MEMBERBIRTHDAY" });
  const invoice = intl.formatMessage({ id: "DASHBOARD.INVOICE" });
  const dueamount = intl.formatMessage({ id: "DASHBOARD.DUEAMOUNT" });
  const expense = intl.formatMessage({ id: "DASHBOARD.EXPENSE" });
  const Profit = intl.formatMessage({ id: "DASHBOARD.PROFIT" });
  const Today = intl.formatMessage({ id: "DASHBOARD.TODAY" });
  const between = intl.formatMessage({ id: "DASHBOARD.BETWEEN" });
  const total = intl.formatMessage({ id: "DASHBOARD.TOTAL" });
  const allExpiredPackage = intl.formatMessage({
    id: "DASHBOARD.ALL.EXPIREPACKAGE",
  });
  const activeMember = intl.formatMessage({
    id: "DASHBOARD.MEMBER.ACTIVE",
  });
  const inActiveMember = intl.formatMessage({
    id: "DASHBOARD.MEMBER.INACTIVE",
  });

  const [showModal, setShowModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState<any>();
  const [cardTitle, setCardTitle] = useState("");
  const [isFrom, setIsFrom] = useState(false);

  const handleShowModal = (
    startDate: any,
    endDate: any,
    title: any,
    isFrom: any = false
  ) => {
    setSelectedDate({ startDate, endDate });
    setCardTitle(title);
    setShowModal(true);
    console.log("isFrom ", isFrom);
    setIsFrom(isFrom);
  };

  // console.log(apiresponse);

  return (
    <>
      {" "}
      <EnquiryModal
        showModal={showModal}
        setShowModal={setShowModal}
        selectedDate={selectedDate}
        cardTitle={cardTitle}
        isFrom={isFrom}
      />
      <Container fluid className="rounded" style={{ background: "#FFFFFF" }}>
        <Card className="w-100">
          <Card.Body className="py-3">
            <Form onSubmit={formik.handleSubmit}>
              <Row className="my-4">
                {" "}
                <Col md={3} className="mb-3">
                  <div className="mb-3">
                    <label htmlFor="gender" className="form-label">
                      {intl.formatMessage({
                        id: "MODULE.MEMBERS.DATERANGE",
                      })}
                    </label>

                    <DateRangePicker
                      value={value}
                      onChange={handleChange}
                      format="MM/dd/yyyy"
                      placeholder="Select Date Range"
                      placement="right"
                      style={{ margin: "2px" }}
                      onClean={handleDateReset}
                    />
                  </div>
                </Col>
                <Col
                  md={4}
                  className="d-flex justify-content-start align-items-center"
                >
                  <Button
                    type="submit"
                    variant="primary"
                    className="me-3 mr-4 "
                    // onClick={(e) => handleSearchClick(e)}
                    // disabled={isLoading}
                  >
                    {intl.formatMessage({
                      id: "COMMON.APPLY",
                    })}
                  </Button>
                  <Button
                    variant="secondary"
                    onClick={handleReset}
                    className="me-3 "
                  >
                    {intl.formatMessage({
                      id: "COMMON.RESET",
                    })}
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>
        <Row className="my-4 ">
          <Col md={3}>
            <div className="bg-light-primary py-5 px-2  rounded-2  mb-7 ">
              <Link
                to={`members/list-members?startDate=${formik.values.startDate}&endDate=${formik.values.endDate}`}
              >
                <DashboardCard
                  title=""
                  date={`${total} ${member}`}
                  subtitle={apiresponse?.totalFixedMember}
                  color="primary"
                />
              </Link>
            </div>
          </Col>
          <Col md={3}>
            <div
              className="bg-light-success py-5 px-2  rounded-2  mb-7 cursor-pointer"
              onClick={() =>
                handleShowModal(
                  formik.values.startDate,
                  formik.values.endDate,
                  "Active Member",
                  true
                )
              }
            >
              <DashboardCard
                title=""
                date={`${total} ${activeMember}`}
                subtitle={apiresponse?.totalactiveMember}
                color="success"
              />
            </div>
          </Col>
          <Col md={3}>
            <div
              className="bg-light-warning py-5 px-2  rounded-2  mb-7 cursor-pointer"
              onClick={() =>
                handleShowModal(
                  formik.values.startDate,
                  formik.values.endDate,
                  "InActive member",
                  true
                )
              }
            >
              <DashboardCard
                title=""
                date={`${total} ${inActiveMember}`}
                subtitle={apiresponse?.totalInactiveMember}
                color="warning"
              />
            </div>
          </Col>
          <Col md={3}>
            <div className="bg-light-primary  py-5 px-2  rounded-2  mb-7">
              <Link
                to={`employees/employee/list?startDate=${formik.values.startDate}&dateType=join&endDate=${formik.values.endDate}`}
              >
                <DashboardCard
                  title=""
                  date={
                    formik.values.startDate === date &&
                    formik.values.endDate === date
                      ? `${Today} ${employee}`
                      : ` ${employee} ${between} ${formik.values.startDate} to ${formik.values.endDate}`
                  }
                  subtitle={apiresponse?.totalEmployee}
                  color="primary"
                  slug="Employees"
                />
              </Link>
            </div>
          </Col>
          <Col md={3}>
            <div
              className="bg-light-warning py-5 px-2  rounded-2  mb-7 cursor-pointer "
              onClick={() =>
                handleShowModal(
                  formik.values.startDate,
                  formik.values.endDate,
                  "Expire Package"
                )
              }
            >
              <DashboardCard
                title=""
                date={
                  formik.values.startDate === date &&
                  formik.values.endDate === date
                    ? `${Today} ${expiry}`
                    : ` ${expiry} ${between} ${formik.values.startDate} to ${formik.values.endDate}`
                }
                subtitle={apiresponse?.totalExpiredPackage}
                color="warning"
                slug="Expired packages "
              />
            </div>
          </Col>
          <Col md={3}>
            <div
              className="bg-light-danger py-5 px-2  rounded-2  mb-7 cursor-pointer"
              onClick={() =>
                handleShowModal(
                  formik.values.startDate,
                  formik.values.endDate,
                  "All Expire Package",
                  true
                )
              }
            >
              <DashboardCard
                title=""
                date={` ${allExpiredPackage}`}
                subtitle={apiresponse?.allExpiredPackage}
                color="danger"
              />
            </div>
          </Col>
          <Col md={3}>
            <div className="bg-light-success py-5 px-2  rounded-2  mb-7 ">
              <DashboardCard
                title=" "
                date={
                  formik.values.startDate === date &&
                  formik.values.endDate === date
                    ? `${Today} ${enrolled}`
                    : `${enrolled} ${between} ${formik.values.startDate} to ${formik.values.endDate}`
                }
                subtitle={apiresponse?.totalEnrolledPackage}
                color="success"
                slug="Enrolled Packages"
              />
            </div>
          </Col>
          <Col md={3}>
            <div
              className="bg-light-danger  py-5 px-2  rounded-2  mb-7 cursor-pointer"
              onClick={() =>
                handleShowModal(
                  formik.values.startDate,
                  formik.values.endDate,
                  "Today Employee Birthday"
                )
              }
            >
              <DashboardCard
                title=""
                date={
                  formik.values.startDate === date &&
                  formik.values.endDate === date
                    ? `${Today} ${empbirth}`
                    : `${empbirth} ${between} ${formik.values.startDate} to ${formik.values.endDate}`
                }
                subtitle={apiresponse?.totalEmployeeBirthday}
                color="danger"
                slug="Employees Birthday"
              />
            </div>
          </Col>
          <Col md={3}>
            <div className="bg-light-danger py-5 px-2  rounded-2  mb-7 ">
              <DashboardCard
                title={currency}
                date={
                  formik.values.startDate === date &&
                  formik.values.endDate === date
                    ? `${Today} ${invoice}`
                    : `${invoice} ${between} ${formik.values.startDate} To ${formik.values.endDate}`
                }
                subtitle={apiresponse?.totalReceipt}
                color="danger"
                slug="Invoices"
              />
            </div>
          </Col>
          <Col md={3}>
            <div
              className="bg-light-warning py-5 px-2  rounded-2  mb-7 cursor-pointer"
              onClick={() =>
                handleShowModal(
                  formik.values.startDate,
                  formik.values.endDate,
                  "Invoice"
                )
              }
            >
              <DashboardCard
                title={currency}
                date={`${total} ${invoice}`}
                subtitle={apiresponse?.totalFixedReceipt}
                color="warning"
              />
            </div>
          </Col>

          <Col md={3}>
            <div
              className="bg-light-success py-5 px-2  rounded-2  mb-7 cursor-pointer"
              onClick={() =>
                handleShowModal(
                  formik.values.startDate,
                  formik.values.endDate,
                  "Remaing Balance"
                )
              }
            >
              <DashboardCard
                title={currency}
                date={`${Today} ${dueamount}`}
                subtitle={apiresponse?.totalFixedDueAmt}
                color="success"
              />
            </div>
          </Col>
          <Col md={3}>
            <div
              className="bg-light-primary py-5 px-2  rounded-2  mb-7cursor-pointer"
              onClick={() =>
                handleShowModal(
                  formik.values.startDate,
                  formik.values.endDate,
                  "Today Member Birthday"
                )
              }
            >
              <DashboardCard
                title=""
                date={
                  formik.values.startDate === date &&
                  formik.values.endDate === date
                    ? `${Today} ${membirth}`
                    : `${membirth} ${between} ${formik.values.startDate} To ${formik.values.endDate}`
                }
                subtitle={apiresponse?.totalMemberBirthDay}
                color="primary"
                slug="Member's Birthday "
              />
            </div>
          </Col>
          <Col md={3}>
            <div
              className="bg-light-danger py-5 px-2  rounded-2  mb-7 cursor-pointer"
              onClick={() =>
                handleShowModal(
                  formik.values.startDate,
                  formik.values.endDate,
                  "Today Expense"
                )
              }
            >
              <DashboardCard
                title={currency}
                date={
                  formik.values.startDate === date &&
                  formik.values.endDate === date
                    ? `${Today} ${expense}`
                    : `${expense} ${between} ${formik.values.startDate} To ${formik.values.endDate}`
                }
                subtitle={apiresponse?.totalexpense}
                color="danger"
                slug="Expenses"
              />
            </div>
          </Col>
          <Col md={3}>
            <div
              className="bg-light-primary py-5 px-2  rounded-2  mb-7 cursor-pointer"
              onClick={() =>
                handleShowModal(
                  formik.values.startDate,
                  formik.values.endDate,
                  "Total Expense"
                )
              }
            >
              <DashboardCard
                title={currency}
                date={`${total} ${expense}`}
                subtitle={apiresponse?.totalFixedExpense}
                color="primary"
              />
            </div>
          </Col>
          <Col md={3}>
            <div
              className="bg-light-success py-5 px-2  rounded-2  mb-7 cursor-pointer"
              // onClick={() =>
              //   handleShowModal(
              //     formik.values.startDate,
              //     formik.values.endDate,
              //     "Total Profit"
              //   )
              // }
            >
              <DashboardCard
                title={currency}
                date={`${total} ${Profit}`}
                subtitle={apiresponse?.totalProfit}
                color="success"
              />
            </div>
          </Col>
          <Col md={3}>
            <div
              className="bg-light-primary  py-5 px-2  rounded-2  mb-7 cursor-pointer "
              onClick={() =>
                handleShowModal(
                  formik.values.startDate,
                  formik.values.endDate,
                  "Enquiry Details"
                )
              }
            >
              <DashboardCard
                title=""
                date={
                  formik.values.startDate === date &&
                  formik.values.endDate === date
                    ? `${Today} ${enquiry}`
                    : `${enquiry} ${between} ${formik.values.startDate} to ${formik.values.endDate}`
                }
                subtitle={apiresponse?.totalEnquiry}
                color="primary"
                slug="Enquiries "
              />
            </div>
          </Col>
          <Col md={3}>
            <div
              className="bg-light-warning py-5 px-2  rounded-2  mb-7 cursor-pointer"
              onClick={() =>
                handleShowModal(
                  formik.values.startDate,
                  formik.values.endDate,
                  "FollowUps"
                )
              }
            >
              <DashboardCard
                title=""
                date={
                  formik.values.startDate === date &&
                  formik.values.endDate === date
                    ? `${Today} ${followup}`
                    : `${followup} ${between} ${formik.values.startDate} To ${formik.values.endDate}`
                }
                subtitle={apiresponse?.totalFollowup}
                color="warning"
                slug="Follouw-ups "
              />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

const DashboardWrapper: FC = () => {
  let ActiveUser: any = localStorage.getItem("activeUserType");
  const UserType = JSON.parse(ActiveUser);
  const intl = useIntl();
  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: "MENU.DASHBOARD" })}
      </PageTitle>
      {UserType !== "member" ? <DashboardPage /> : <DashboardMember />}
    </>
  );
};

export { DashboardWrapper };

<>
  {/* begin::Row */}
  {/* <div className="row gy-5 g-xl-8">
  
  {/* <div className="col-xxl-4">
    <MixedWidget2
      className="card-xl-stretch mb-xl-8"
      chartColor="danger"
      chartHeight="200px"
      strokeColor="#cb1e46"
    />
  </div> */}
  {/* <div className="col-xxl-4">
    <ListsWidget5 className="card-xxl-stretch" />
  </div> */}
  {/* <div className="col-xxl-4">
    <MixedWidget10
      className="card-xxl-stretch-50 mb-5 mb-xl-8"
      chartColor="primary"
      chartHeight="150px"
    />
    <MixedWidget11
      className="card-xxl-stretch-50 mb-5 mb-xl-8"
      chartColor="primary"
      chartHeight="175px"
    />
  </div> 
</div> */}
  {/* end::Row */}

  {/* begin::Row */}
  {/* <div className="row gy-5 gx-xl-8">
  <div className="col-xxl-4">
    <ListsWidget3 className="card-xxl-stretch mb-xl-3" />
  </div>
  <div className="col-xl-8">
    <TablesWidget10 className="card-xxl-stretch mb-5 mb-xl-8" />
  </div>
</div> */}
  {/* end::Row */}

  {/* begin::Row */}
  {/* <div className="row gy-5 g-xl-8">
  <div className="col-xl-4">
    <ListsWidget2 className="card-xl-stretch mb-xl-8" />
  </div>
  <div className="col-xl-4">
    <ListsWidget6 className="card-xl-stretch mb-xl-8" />
  </div>
  <div className="col-xl-4">
    <ListsWidget4 className="card-xl-stretch mb-5 mb-xl-8" items={5} />
    {/* partials/widgets/lists/_widget-4', 'class' => 'card-xl-stretch mb-5 mb-xl-8', 'items' => '5' */}
  {/* </div>
</div> */}
  {/* end::Row */}

  {/* <div className="row g-5 gx-xxl-8">
  <div className="col-xxl-4">
    <MixedWidget8
      className="card-xxl-stretch mb-xl-3"
      chartColor="success"
      chartHeight="150px"
    />
  </div>
  <div className="col-xxl-8">
    <TablesWidget5 className="card-xxl-stretch mb-5 mb-xxl-8" />
  </div>
</div> */}
</>;
