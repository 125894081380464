import { Action } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export interface ActionWithPayload<T> extends Action {
  payload?: T;
}

export const actionTypes = {
  GetPaymentPurchase: "[GetPaymentPurchase] Action",
};

const initialAuthState: ICenterState = {
  paymentPurchase: undefined,
};

export interface ICenterState {
  paymentPurchase?: any;
}

export const reducer = persistReducer(
  { storage, key: "v100-demo1-auth", blacklist: ["paymentPurchase"] },
  (
    state: ICenterState = initialAuthState,
    action: ActionWithPayload<ICenterState>
  ) => {
    switch (action.type) {
      case actionTypes.GetPaymentPurchase: {
        const paymentPurchase = action.payload?.paymentPurchase;
        return { ...state, paymentPurchase };
      }
      default:
        return state;
    }
  }
);

export const actions = {
  GetPaymentPurchase: (paymentPurchase: string) => ({
    type: actionTypes.GetPaymentPurchase,
    payload: { paymentPurchase },
  }),
};
