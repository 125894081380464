import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL || "http://localhost:8000";
const LANG_URL = process.env.REACT_APP_I18NLANG_API_KEY;
export const GET_ALL_LANGUAGE = `${API_URL}/v1/languagelist`;
export const GET_LANGUAGE_FILE_BRANCHES = `${LANG_URL}/translation`;
export const GET_ALL_MODULES = `${API_URL}/v1/activemodules/list`;
export const GET_ACTIVE_LOGTYPE = `${API_URL}/v1/qrattandancelogtype/listbyBranch`;
export const ADD_MEMBER_ATTANDANCE_QR = `${API_URL}/v1/centermember/qrattandance/addmember`;
export const ADD_EMPLOYEE_ATTANDANCE_QR = `${API_URL}/v1/qrattandance/addemployee`;

//create branch
export function addMemberAttandanceByQR(body: any) {
  return axios.post(ADD_MEMBER_ATTANDANCE_QR, body).catch((error) => {
    return error.response;
  });
}
export function addEmployeeAttandanceByQr(body: any) {
  return axios.post(ADD_EMPLOYEE_ATTANDANCE_QR, body).catch((error) => {
    return error.response;
  });
}
export function GetAllLanguages() {
  return axios.get(GET_ALL_LANGUAGE).catch((error) => {
    return error.response;
  });
}
export function getallmodules() {
  return axios.get(GET_ALL_MODULES).catch((error) => {
    return error.response;
  });
}
export function getActiveLogType(branchuuid: any) {
  return axios.get(`${GET_ACTIVE_LOGTYPE}/${branchuuid}`).catch((error) => {
    return error.response;
  });
}

//getting all branches

export function GetLanguageFile(languageSlug: string = "en") {
  return axios
    .get(`${GET_LANGUAGE_FILE_BRANCHES}/${languageSlug}/language.json`)
    .catch((error) => {
      return error.response;
    });
}

// export function GetLanguageFile(languageSlug: string) {
//   if (languageSlug !== null) {
//     return axios.get(GET_LANGUAGE_FILE_BRANCHES + '/' + languageSlug+'/language.json').catch((error) => {
//       return error.response
//     })
//   } else {
//     return axios.get(GET_LANGUAGE_FILE_BRANCHES + '/en/language.json').catch((error) => {
//       return error.response
//     })
//   }
// }
