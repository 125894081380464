import React from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";
import { checkIsActive, KTSVG } from "../../../helpers";
import { useLayout } from "../../core";
import { GetTopbarItems } from "./redux/DownloadCrud";
import swal from "sweetalert";
import { useIntl } from "react-intl";

type Props = {
  to: string;
  title: string;
  icon?: string;
  fontIcon?: string;
  hasBullet?: boolean;
};

const AsideMenuItem: React.FC<Props> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet = false,
}) => {
  const { pathname } = useLocation();
  const isActive = checkIsActive(pathname, to);
  const { config } = useLayout();
  const { aside } = config;
  let selectedBranch: any = localStorage?.getItem("ActiveBranch");
  const ActiveBranch = JSON.parse(selectedBranch);
  // const planExpireINDays = 10;
  const ActiveBranchID = ActiveBranch?.iBranchID;
  const intl = useIntl();

  const handleDownloadBackup = async () => {
    try {
      // Get ActiveBranchID from localStorage
      const activeBranchId = localStorage.getItem("ActiveBranch");

      if (activeBranchId) {
        // Call the API to fetch the backup data
        const response = await GetTopbarItems(ActiveBranchID);

        // Log the response for debugging
        console.log(response);

        // Check if the response contains the downloadablePath
        if (
          response?.status === 200 &&
          response?.data?.data?.downloadablePath
        ) {
          const downloadablePath = response?.data?.data?.downloadablePath;

          // Trigger the download using a hidden anchor element
          const link = document.createElement("a");
          link.href = downloadablePath;
          link.download = ""; // Optional: set a custom filename
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          console.log("Backup file download triggered.");
        } else {
          console.error("Downloadable path not found in the response.");
          swal(response?.data?.replyMsg, { icon: "error" });
        }
      } else {
        console.warn("No ActiveBranch found in localStorage.");
        swal(intl.formatMessage({ id: "BACKUP.SELECTBRANHC" }), {
          icon: "error",
        });
      }
    } catch (error: any) {
      console.error("Error fetching or downloading the backup file:", error);
      swal(error?.response?.data?.replyMsg, { icon: "error" });
    }
  };

  return (
    <div className="menu-item">
      {title === "Download Backup" ? (
        <div
          className={clsx("menu-link without-sub", { active: isActive })}
          onClick={() => handleDownloadBackup()}
        >
          {hasBullet && (
            <span className="menu-bullet">
              <span className="bullet bullet-dot"></span>
            </span>
          )}
          {icon && aside.menuIcon === "svg" && (
            <span className="menu-icon">
              <KTSVG path={icon} className="svg-icon-2" />
            </span>
          )}
          {fontIcon && aside.menuIcon === "font" && (
            <i className={clsx("bi fs-3", fontIcon)}></i>
          )}
          <span className="menu-title">{title}</span>
        </div>
      ) : (
        <Link
          className={clsx("menu-link without-sub", { active: isActive })}
          to={to}
        >
          {hasBullet && (
            <span className="menu-bullet">
              <span className="bullet bullet-dot"></span>
            </span>
          )}
          {icon && aside.menuIcon === "svg" && (
            <span className="menu-icon">
              <KTSVG path={icon} className="svg-icon-2" />
            </span>
          )}
          {fontIcon && aside.menuIcon === "font" && (
            <i className={clsx("bi fs-3", fontIcon)}></i>
          )}
          <span className="menu-title">{title}</span>
        </Link>
      )}
      {children}
    </div>
  );
};

export { AsideMenuItem };
