import { Action } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export interface ActionWithPayload<T> extends Action {
  payload?: T;
}

export const actionTypes = {
  GetSupplier: "[GetSupplier] Action",
};

const initialAuthState: ICenterState = {
  supplier: undefined,
};

export interface ICenterState {
  supplier?: any;
}

export const reducer = persistReducer(
  { storage, key: "v100-demo1-auth", blacklist: ["supplier"] },
  (
    state: ICenterState = initialAuthState,
    action: ActionWithPayload<ICenterState>
  ) => {
    switch (action.type) {
      case actionTypes.GetSupplier: {
        const supplier = action.payload?.supplier;
        return { ...state, supplier };
      }
      default:
        return state;
    }
  }
);

export const actions = {
  GetSupplier: (supplier: string) => ({
    type: actionTypes.GetSupplier,
    payload: { supplier },
  }),
};
