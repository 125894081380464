import { Action } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export interface ActionWithPayload<T> extends Action {
  payload?: T;
}

export const actionTypes = {
  GetMyDeitPlan: "[GetMyDeitPlan] Action",
};

const initialAuthState: ICenterState = {
  myDietPlan: undefined,
};

export interface ICenterState {
  myDietPlan?: any;
}

export const reducer = persistReducer(
  { storage, key: "v100-demo1-auth", blacklist: ["myDietPlan"] },
  (
    state: ICenterState = initialAuthState,
    action: ActionWithPayload<ICenterState>
  ) => {
    switch (action.type) {
      case actionTypes.GetMyDeitPlan: {
        const myDietPlan = action.payload?.myDietPlan;
        return { ...state, myDietPlan };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  GetMyDeitPlan: (myDietPlan: string) => ({
    type: actionTypes.GetMyDeitPlan,
    payload: { myDietPlan },
  }),
};
