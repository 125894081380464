import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useIntl } from "react-intl";
import QrReaderWithRef from "./QrReaderWithRef";
import AddAttandanceModal from "./AddAttandanceModal";
import {
  addEmployeeAttandanceByQr,
  addMemberAttandanceByQR,
} from "../redux/HeadersCRUD";
import swal from "sweetalert";

const QRModal = ({ show, setShow }: any) => {
  const userType = JSON.parse(localStorage?.getItem("activeUserType") || "{}");
  const activeBranch = JSON.parse(localStorage.getItem("ActiveBranch") || "{}");
  // const ActiveBranchUUID =
  //   userType === "member"
  //     ? activeBranch?.[0]?.iBranchUUID
  //     : activeBranch?.iBranchUUID;
  const IsLogType =
    userType === "member"
      ? activeBranch?.[0]?.displayQRAttendanceLogType
      : activeBranch?.displayQRAttendanceLogType;
  const MemberProfile = JSON.parse(localStorage?.getItem("activeUser") || "{}");
  const MemberID = MemberProfile?.member_id;
  const EMPProfile = JSON.parse(
    localStorage?.getItem("employeeDetails") || "{}"
  );
  const EmpID = EMPProfile?.employee_id;
  const intl = useIntl();
  const [qrResult, setQrResult] = useState(null);
  const [isScannerActive, setIsScannerActive] = useState(true);
  const [isAddAttendanceModalVisible, setIsAddAttendanceModalVisible] =
    useState(false);

  const handleClose = () => {
    setQrResult(null);
    setIsScannerActive(true);
    setShow(false);
    console.log("running handle close");
  };
  // console.log(IsLogType, typeof IsLogType, "islogtype");
  const handleScanSuccess = (data: any) => {
    if (IsLogType === "1") {
      setQrResult(data);
      setIsScannerActive(false);
      setIsAddAttendanceModalVisible(true);
    } else {
      handleAddAttandance(data);
    }
  };
  const handleAddAttandance = async (scanData: any) => {
    // setSelectLog(selectedOption);
    if (scanData?.branchID) {
      console.log(scanData, "scandata");
      const formData: any = {
        branchID: scanData?.branchID,
        // logType: selectedOption?.value,
      };
      try {
        let response;
        if (userType === "member") {
          formData.memberID = MemberID;
          response = await addMemberAttandanceByQR(formData);
        } else if (userType === "employee") {
          formData.employeeID = EmpID;
          response = await addEmployeeAttandanceByQr(formData);
        }
        if (response?.status === 200) {
          swal(response?.data?.replyMsg, { icon: "success" });
          handleClose();
        } else {
          swal(response?.data?.replyMsg, { icon: "error" });
        }
      } catch (error) {
        console.error("Error submitting attendance:", error);
        swal("An error occurred. Please try again.", { icon: "error" });
      }
    }
  };

  const handleAddAttendanceSuccess = () => {
    setIsAddAttendanceModalVisible(false);
    handleClose();
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title className="w-100">
            <div className="d-flex justify-content-between">
              <div>{intl.formatMessage({ id: "QR.SCANNER.ATTANDANCE" })}</div>
              {!isScannerActive && (
                <button
                  className="btn btn-primary"
                  onClick={() => setIsScannerActive(true)}
                >
                  Scan Again
                </button>
              )}
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body scroll-y mx-2 mx-xl-5 my-2">
          {isScannerActive && <QrReaderWithRef onScan={handleScanSuccess} />}
        </Modal.Body>
      </Modal>

      {isAddAttendanceModalVisible && (
        <AddAttandanceModal
          show={isAddAttendanceModalVisible}
          setShow={setIsAddAttendanceModalVisible}
          scanData={qrResult}
          onSuccess={handleAddAttendanceSuccess}
        />
      )}
    </>
  );
};

export default QRModal;
