import { useIntl } from "react-intl";
import { QrReader } from "react-qr-reader";
import swal from "sweetalert";

const QrReaderWithRef = ({ onScan }: { onScan: (data: string) => void }) => {
  const intl = useIntl();

  const handleScan = (result: any) => {
    if (result) {
      const text = result?.text || result;

      try {
        const parsedText = JSON.parse(text);
        console.log(parsedText, "parsedtext");

        if (parsedText?.branchID && parsedText?.iAdminID) {
          onScan(parsedText); // Pass valid data to parent component
        } else {
          throw new Error("Invalid QR Code: Missing branchID or iAdminID");
        }
      } catch (error) {
        swal(intl.formatMessage({ id: "QR.CODE.INVALID" }), { icon: "error" });
      }
    }
  };

  return (
    <div>
      <h2>{intl.formatMessage({ id: "QR.SCANNER" })}</h2>
      <QrReader
        onResult={handleScan}
        constraints={{ facingMode: "environment" }} // Use rear camera
      />
    </div>
  );
};

export default QrReaderWithRef;
